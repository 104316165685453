import { useEffect, useState } from 'react';
import {FiInfo,FiPlay,FiPause} from 'react-icons/fi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown,faDownload,faInfo } from '@fortawesome/free-solid-svg-icons';
import { faPlay,faPause } from '@fortawesome/free-solid-svg-icons';

const AudioTile = ({audio,image,pdf,title}) => {

    var [playIcon,setPlayIcon] = useState("play");

    const playButton = () =>{ 
        if(playIcon == "play"){
            setPlayIcon("pause");
        }
        else if(playIcon == "pause"){
            setPlayIcon("play");
        }
    }


    const info = () => {
        document.querySelector(".audio-tile .info").classList.toggle("hidden");
    }

    const audioPlayer = () => {

        const audio = document.getElementById("audio"+title);
        const durationContainer = document.getElementById("duration"+title);
        const seekSlider = document.getElementById("seek-slider"+title);
        const currentTimeContainer = document.getElementById("current-time"+title);
        const playIconContainer = document.getElementById("play-icon"+title);
        //const volumeSlider = document.getElementById("volume-slider"+title);
        var playState = "play";
        let rAF = null;

        seekSlider.value = 0;
        //volumeSlider.value = 70;
        audio.volume = 1;

        const whilePlaying = () => {
            seekSlider.value = Math.floor(audio.currentTime);
            currentTimeContainer.textContent = calculateTime(seekSlider.value);
            rAF = requestAnimationFrame(whilePlaying);
          }

        //Player Duration

        const calculateTime = (secs) => {
            const minutes = Math.floor(secs / 60);
            const seconds = Math.floor(secs % 60);
            const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${minutes}:${returnedSeconds}`;
          }

        const displayDuration = () => {
            durationContainer.textContent = calculateTime(audio.duration);
        }

        const setSliderMax = () => {
            seekSlider.max = Math.floor(audio.duration);
        }

        if (audio.readyState > 0) {
            displayDuration();
            setSliderMax();
        } else {
            audio.addEventListener('loadedmetadata', () => {
                displayDuration();
                setSliderMax();
            });
        }

        seekSlider.addEventListener('input', () => {
            currentTimeContainer.textContent = calculateTime(seekSlider.value);
            if(!audio.paused) {
                cancelAnimationFrame(rAF);
            }
        });

        seekSlider.addEventListener('change', () => {
            audio.currentTime = seekSlider.value;
            if(!audio.paused) {
                requestAnimationFrame(whilePlaying);
            }
          });

        audio.addEventListener('timeupdate', () => {
            seekSlider.value = Math.floor(audio.currentTime);
        });

        playIconContainer.addEventListener('click', () => {
            if(playState === 'play') {
              audio.play();
              requestAnimationFrame(whilePlaying);
              playState = 'pause';
            } else {
              audio.pause();
              cancelAnimationFrame(rAF);
              playState = 'play';
            }
          });

        /*volumeSlider.addEventListener('input', (e) => {
            const value = e.target.value;
            
            audio.volume = value / 100;
        });*/
    }

    useEffect(() => {
        audioPlayer();
    },[]);

    return (
        <div className='audio-background'>
            <div className="audio-tile">
                <img src={image} alt="" className="audio-image"/>
                {title == "Uniti per sempre" ? (<a className="audio-info" onClick={info} download={title}>
                    <FontAwesomeIcon onClick={info} icon={faInfo} size="1x" />
                </a>) : (<div />)}
                <a href={pdf} className="audio-download" download={title}>
                    <FontAwesomeIcon icon={faDownload} size="1x" />
                </a>
                {title == "Uniti per sempre" ? (<div className="info hidden">
                        <h2>Vito Antonio Ceravolo, fratello di Franco, è coautore di questo brano. Ha collaborato come autore-compositore nella realizzazione di tanti brani, alcuni di musica sacra e tanti altri di musica folk e leggera.</h2>
                    </div>): <div />}
                <div className="audio-content">                    
                    <div className="top">
                        <h2>{title}</h2>
                    </div>
                    <div className="bottom">
                        <div className="audio-player">
                            <audio src={audio} preload="metadata" className="audio" id={"audio"+title} loop></audio>
                            <button className="play-icon" id={"play-icon"+title} onClick={playButton}>
                                {playIcon == "play" ? (<FontAwesomeIcon icon={faPlay} size="1x" />) : (<FontAwesomeIcon icon={faPause} size="1x" />) }
                            </button>
                            <span className="current-time" id={"current-time"+title} class="time">0:00</span>
                            <input type="range" className="seek-slider" id={"seek-slider"+title} max={100} />
                            <span className="duration" id={"duration"+title} class="time">0:00</span>
                            {/*<input type="range" className="volume-slider" id={"volume-slider"+title} max={100} />*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default AudioTile;