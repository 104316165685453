import Social from "../models/Social";
import Letter from "../models/Letter";

const Intro = () => {
    return (
        <div className="intro">
            <img src="./images/background.jpg" alt="" className="background" />
            <div className="titles">
                <div className="title-s">                  
                    <Letter letter="M" />                
                    <Letter letter="U" />
                    <Letter letter="S" />
                    <Letter letter="I" />
                    <Letter letter="C" />
                    <Letter letter="A" />
                </div>
                <div className="title-s">
                    <Letter letter="S" />
                    <Letter letter="A" />
                    <Letter letter="C" />
                    <Letter letter="R" />
                    <Letter letter="A" />
                </div>
                <h3 className="subtitle">Medico e Compositore</h3>
                <h1 className="title">Franco Ceravolo</h1>
            </div>
        </div>
    );
}
 
export default Intro;