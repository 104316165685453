import Event from "../models/Event";

const ListEvent = () => {
    return (
        <div className="list-event">
            <div className="list-title">
                <h2 className="title">Articoli Noti</h2>
            </div>
            <div className="list-content">
                <Event 
                    link="https://www.ilvibonese.it/cultura/16563-musica-sacra-torrefranca-omaggia-medico-compositore-franco-ceravolo/" 
                    image="./images/event.jpeg"
                    description="Musica sacra, il “Torrefranca” omaggia il medico-compositore Franco Ceravolo"
                />
                <Event 
                    link="https://www.calabresi.net/2010/01/20/sulle-ali-della-carita/11695/" 
                    image="./images/libro.jpg"
                    description="LIBRO: “SULLE ALI DELLA CARITA’"
                />
            </div>
        </div>
    );
}
 
export default ListEvent;