import Bio from "./Bio";
import Intro from "./Intro";
import Cit from "./Cit";
import Opus from "./Opus";
import ListEvent from "./ListEvent";

const Home = () => {
    return (
        <div className="home">
            <Intro />
            <Bio />
            <Cit />
            {/*<Opus />*/}
            <ListEvent />
        </div>
    );
}
 
export default Home;