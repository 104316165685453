import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Composition from "./composition/Composition";
import Home from "./home/Home";
import ScrollToTop from "./models/ScrollToTop";
import Navbar from "./Navbar";
import { Helmet } from 'react-helmet';
import Footer from "./models/Footer";

function App() {

  return (
    <Router>
      <Helmet>
        <title>Franco Ceravolo Musica</title>
        <meta name="description" content="Franco Ceravolo Musica" />
        <meta name="keywords" content="franco ceravolo, musica sacra, franco ceravolo musica sacra" />
        <meta name="author" content="Nicolò Talesa" />
        <meta name="google-site-verification" content="VU88AA1jPp4ltDRMK7SIO6mtZPkpG9Nbv4jgOnItS_0" />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/composizioni">
          <Composition />
        </Route>
        <Route
          path="*"
          render={() => {
            return (
              <Redirect to="/" /> 
          )
        }}
      />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
