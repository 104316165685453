const Letter = ({letter}) => {
    return (
        <div className="letter">
            <div className="letter-inner">
                <div className="letter-front">
                    <h1>{letter}</h1>
                </div>
                <div className="letter-back">
                    <h1>{letter}</h1>
                </div>
            </div>
        </div>
    );
}
 
export default Letter;