const Footer = () => {
    return (
        <div className="footer">
            <center>
                <h3>&copy; Copyright by Franco Ceravolo</h3>
            </center>
        </div>
    );
}
 
export default Footer;