const Intro_Composition = () => {
    return (
        <div className="intro-composition">
            <div className="background">
                <img src={process.env.PUBLIC_URL+"/images/background-composition2_50.jpeg"} alt="" />
            </div>
            <div className="title">
                <h1>MUSICA SACRA</h1>
            </div>
        </div>
    );
}
 
export default Intro_Composition;