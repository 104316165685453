import { useEffect} from "react";
import {Link} from "react-router-dom";
import {FiMenu} from 'react-icons/fi';

const Navbar = () => {

    useEffect(() => {
        window.addEventListener("scroll", () => {scrollFunction()});

        const scrollFunction = () => {
            if(window.innerWidth > 800){
                if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                    document.querySelector(".navbar").style.paddingTop = "0.5vh";
                    document.querySelector(".navbar").style.paddingBottom = "0.5vh";
                } else {
                    document.querySelector(".navbar").style.paddingTop = "1vh";
                    document.querySelector(".navbar").style.paddingBottom = "1vh";
                }
            }
            else{
                document.querySelector(".navbar").classList.remove("responsive");
                if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                    document.querySelector(".navbar").style.paddingTop = "1.5vh";
                    document.querySelector(".navbar").style.paddingBottom = "1.5vh";
                } else {
                    document.querySelector(".navbar").style.paddingTop = "2vh";
                    document.querySelector(".navbar").style.paddingBottom = "2vh";
                }
            }
        }
    },[]);

    const click = () => {
        document.querySelector(".navbar").classList.toggle("responsive");
    }

    return (
        <div className="navbar">
            <div className="leading">
                    <img src="./images/logo.jpeg" className="logo" alt="" />
                    <Link to="/" className="title">FRANCO CERAVOLO</Link>
            </div>
    
            <FiMenu className="menu-btn" onClick={click} />
            <div className="actions">
                <br /> 
                <Link to="/">
                    HOME
                </Link>
                <br />
                <Link to="/composizioni">
                    COMPOSIZIONI
                </Link>
            </div>
        </div>
    );
}
 
export default Navbar;