const Cit = () => {
    return (
        <div className="cit">
            <div className="description">
                <p>
                    <span>“</span>le note, le parole e le emozioni devono sapersi
                    intrecciare e fondere per cantare la bellezza che ci sovrasta ed aprirci alla condivisione di un amore, quello
                    divino, che incessantemente ci raggiunge e ci abita<span>„</span>
                </p>
            </div>
        </div>
    );
}
 
export default Cit;