import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faTwitter,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Social = () => {
    return (
        <div className="social">
            <a href="/"
                className="instagram s">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="/"
                className="facebook s">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="/"
                className="twitter s">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
            <a href="mailto:francoceravolo.compositore@gmail.com"
                className="mail s">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
        </div>
    );
}
 
export default Social;