const Event = ({link,image,description}) => {
    
    return (
        <a href={link}>
            <div className="event">
                <div className="event-photo">
                    <img src={image} alt="" />
                </div>
                <div className="event-title">
                    <h2 className="title">{description}</h2>
                </div>
            </div>  
        </a>
    );
}
 
export default Event;