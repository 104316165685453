import Intro_Comp from "./IntroComposition";
import ListAudio from "./ListAudio";
import data from "../data.json";

const Composition = () => {

    const list = data["list-audio"];

    return (
        <div className="composition">
            <Intro_Comp />
            {list.map((e) => (
                <ListAudio title={e.title} list={e.list} key={e.title} />
            ))}
        </div>
    );
}
 
export default Composition;