import AudioTile from "../models/AudioTile";

const ListAudio = ({title,list}) => {

    return (
        <div className="list-audio">
            <div className="header">
                <h2 className="title">
                    {title}
                </h2>
            </div>
            <div className="content">
                {
                    list && list.map(e => (
                        <AudioTile audio={e.audio} image={e.image} pdf={e.pdf} title={e.title} key={e.title} /> 
                    ))
                }
            </div>
        </div>
    );
}
 
export default ListAudio;