import { useState } from "react";
import Social from "../models/Social";

const Bio = () => {

    const [text,setText] = useState(
        [`Franco Ceravolo- compositore di musica sacra.
        Nasce a `,`1Monterosso Calabro (VV)`,`, dove oggi vive e svolge l’attività di `,`1medico`,`.
        Autore di saggi teologici e di testi poetici sacri, ci consegna negli anni un’ampia antologia di componimenti
        che pure comprende lo `,`1Stabat Mater`,`, il `,`1Magnificat`,`, alcune Messe ed altri brani liturgici e mariani.`,`2`,
        `La sua opera compositiva è “un lavoro appassionato che predilige la riflessione di temi sacri unitamente alla
        stesura di linee melodiche ed armoniche che si compongono in struttura polifonica... sa proporre insieme la
        bellezza della musica ed il bisogno dell’uomo di riavvicinarsi alla fede...riesce pure a fondere le linee
        melodiche della polifonia classica con le istanze polifoniche moderne, ben interpretando il senso semantico
        di ogni singola parola e di ogni frase”   `]
    );
    const [expanded, setExpanded] = useState(false);

    const expandText = (e) => {
        if(expanded){
            setText(
                [`Franco Ceravolo- compositore di musica sacra.
                Nasce a `,`1Monterosso Calabro (VV)`,`, dove oggi vive e svolge l’attività di `,`1medico`,`.
                Autore di saggi teologici e di testi poetici sacri, ci consegna negli anni un’ampia antologia di componimenti
                che pure comprende lo `,`1Stabat Mater`,`, il `,`1Magnificat`,`, alcune Messe ed altri brani liturgici e mariani.`,`2`,
                `La sua opera compositiva è “un lavoro appassionato che predilige la riflessione di temi sacri unitamente alla
                stesura di linee melodiche ed armoniche che si compongono in struttura polifonica... sa proporre insieme la
                bellezza della musica ed il bisogno dell’uomo di riavvicinarsi alla fede...riesce pure a fondere le linee
                melodiche della polifonia classica con le istanze polifoniche moderne, ben interpretando il senso semantico
                di ogni singola parola e di ogni frase”   `]
            );
            e.target.textContent = "Mostra di più...";
        }
        else{
            setText(
                [`Franco Ceravolo- compositore di musica sacra.
                Nasce a `,`1Monterosso Calabro (VV)`,`, dove oggi vive e svolge l’attività di `,`1medico`,`.
                Autore di saggi teologici e di testi poetici sacri, ci consegna negli anni un’ampia antologia di componimenti
                che pure comprende lo `,`1Stabat Mater`,`, il `,`1Magnificat`,`, alcune Messe ed altri brani liturgici e mariani.`,`2`,
                `La sua opera compositiva è “un lavoro appassionato che predilige la riflessione di temi sacri unitamente alla
                stesura di linee melodiche ed armoniche che si compongono in struttura polifonica... sa proporre insieme la
                bellezza della musica ed il bisogno dell’uomo di riavvicinarsi alla fede...riesce pure a fondere le linee
                melodiche della polifonia classica con le istanze polifoniche moderne, ben interpretando il senso semantico
                di ogni singola parola e di ogni frase”.`,`2`,   
                `È l’autore ad affermare che, nel comporre brani sacri, “le note, le parole e le emozioni devono sapersi
                intrecciare e fondere per cantare la bellezza che ci sovrasta ed aprirci alla condivisione di un amore, quello
                divino, che incessantemente ci raggiunge e ci abita”.`,
                `2`,`Il 28 Maggio 2019, viene eseguito lo Stabat Mater dal `,`1coro “F. Torrefranca”`,` e dal `,`1Quartetto d’archi “Idorì” `,
                `del Conservatorio di Vibo Valentia, diretti rispettivamente dal m.stro `,`1Gianfranco Cambareri`, ` e dalla prof.ssa `,
                `1Margherita Franceschini`,`.
                Il 18 Maggio 2021, viene presentato il `,`1CD “Chiese di Calabria”`, ` (coautori l’arch. `,`1Rosario Chimirri `, `e l’ing. `,`1Pino
                Greco`,`); contiene alcuni suoi brani trascritti per organo che fanno da sottofondo allo scorrere di immagini e
                testi recitativi.   `]
            );
            e.target.textContent = "Mostra di meno";
        }
        setExpanded(!expanded);
    }

    return (
        <div className="bio">
            <div className="bio-header">
                <img src="./images/profile_pic.jpeg" alt="" />
                <h2 className="name">Franco Ceravolo</h2>
                <Social />
            </div>
            <div className="bio-content">
                <h1 className="title">BIOGRAFIA</h1>
                <p>{text.map((e) => {
                    if(e[0] == "1"){
                        const a = e.replace("1","")
                        return <b>{a}</b>
                    }
                    if(e[0] == "2"){
                        return <br />
                    }
                    if(e[0] == "3"){
                        const a = e.replace("3","");
                        return <i>{a}</i>
                    }
                    return e;
                })}</p>
                <button onClick={expandText}>Mostra di più...</button>
            </div>
        </div>
    );
}
 
export default Bio;